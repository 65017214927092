import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql, Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';
import React from 'react';

import { Layout, SEO } from '../../components';
import './style.scss';

const Profile = ({ name, title, path, image }) => {
  return (
    <Link
      to={path}
      className="profile cursor-pointer flex flex-col items-center m-8"
    >
      <Img alt={name} fixed={image.childImageSharp.fixed} />
      <h2 className="mt-4 text-2xl font-emp font-semibold">{name}</h2>
      <p className="my-4 font-medium">{title}</p>

      <p className="mt-4 p-2 font-medium bg-primary text-white w-full text-center opacity-0">
        See profile
        <FontAwesomeIcon className="ml-4" icon={faArrowRight} />
      </p>
    </Link>
  );
};

const AboutUs = ({ data: { membersQuery, headerImage } }) => {
  const members = membersQuery.edges;

  return (
    <Layout bodyClass="page-team standard-layout text-justify">
      <SEO title="About" url="about" />

      <BackgroundImage
        Tag="section"
        className="header-section bg-black"
        fluid={headerImage.childImageSharp.fluid}
      >
        <div className="container">
          <h1>About Us</h1>
          <p className="text-justify md:text-left">
            We help everybody stay safe online!
          </p>
        </div>
      </BackgroundImage>

      <div className="container">
        <section className="body-section">
          <h2>Meet the Team</h2>
          <p>
            Yes, it is just me for now. I am currently in the process of
            gathering more people.
          </p>

          <div className="flex flex-wrap justify-around mt-8">
            {members.map(({ node: { frontmatter: it } }) => (
              <Profile
                key={it.path}
                path={it.path}
                name={it.name}
                title={it.jobTitle}
                image={it.image}
              />
            ))}
          </div>

          <h2>Our vision</h2>
          <p>
            We believe in a world where everybody is safe online. No more{' '}
            <strong>phishing</strong>, no more <strong>hacking</strong> and no
            more <strong>spam</strong>.
          </p>
          <p>
            We believe in a world without <strong>fake websites</strong>.
          </p>
          <p>We believe in a world where all the emails are legit.</p>
          <p>
            We're gonna do this person by person. We try to reach as many people
            as possible and help them on their journey.
          </p>

          <h2>We cannot do this without you</h2>
          <p>Of course we cannot do this without YOUR help.</p>
          <p>
            Firstly, please <strong>STAY SAFE ONLINE</strong>. We are putting
            together the best online resource for personal cyber-security to aid
            you here.
          </p>
          <p>
            Secondly, please help us spread the word. If you learned something
            from us, please share it with your friends so they can protect
            themselves better.
          </p>
          <p>
            If you got this far, congratulations! We really appreciate your
            support!
          </p>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query TeamQuery {
    membersQuery: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//team/" } }
      sort: { fields: [frontmatter___weight], order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            name
            path
            image {
              childImageSharp {
                fixed(width: 200, quality: 100) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            jobTitle
          }
        }
      }
    }

    headerImage: file(
      relativePath: {
        eq: "about-us/javier-allegue-barros-i5Kx0P8A0d4-unsplash.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default AboutUs;
